button.button.forgot__password__button {
  width: 100%;
  margin-top: 0px;
}

.remember_box {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

div.remember_box > label,
div.remember_box > input {
  width: initial;
  padding: 0;
  transform: scale(0.85);
}
