.forgot__password__container {
  position: relative;
}

.red__button {
  background: #e76c67;
  border: 1px solid #e76c67;
}

button.button.close__button {
  display: flex;
  position: absolute;
  top: 0.15rem;
  right: 0.6rem;
  min-height: initial;
  width: 3rem;
  z-index: 10;
  height: 3.5rem;
}
