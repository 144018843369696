.siteTitle {
  border: none;
  font-weight: 500;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background: var(--lightBlue);
  box-shadow: 0px 0px 7px var(--buttonBlue);
}

.siteTitle:hover {
  box-shadow: 0px 0px 15px var(--lightBlue);
}

.siteTitle h1 {
  padding: 0.9rem;
}

